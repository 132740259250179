import { priorityQueue } from "async";

import { ConnectionFactory } from "./YiiRoutes";
import {ExecutionEnvironment} from "../stores/InterfaceStore";

let concurrentConnections = 4;

if (ExecutionEnvironment.canUseDOM) {
    const {maxConnectionsPerServer} = window as IWindow;

    if (maxConnectionsPerServer && maxConnectionsPerServer >= 0) {
        const browserMaxAfterOverhead: number = maxConnectionsPerServer - 2;
        if (browserMaxAfterOverhead < 1) {
            concurrentConnections = 1;
        } else {
            concurrentConnections = browserMaxAfterOverhead;
        }
    }
}

export const watsonGetQueue = priorityQueue(function(task, callback) {
    task.url(task.params, task.options)
        .then(result => {
            callback(result);
        })
}, concurrentConnections)

// create a queue object with concurrency 2
const ajaxGetQueue = priorityQueue(function(task, callback) {
    /*
    task definition:
    {
        url: String,
        data: Object (optional),
        dataType: String (optional),
    }
     */
    ConnectionFactory(
        task.orgID,
        task.url,
        {
            data: task.data,
        }
    ).then(function (results) {
        callback(null, results);
    }).catch(function (error) {
        callback(error, null);
    });
}, concurrentConnections);

export default ajaxGetQueue;
