import {
    useCallback,
    useContext,
    forwardRef,
    useState,
    useRef,
    useEffect,
    RefObject,
    SetStateAction,
    PropsWithChildren, useImperativeHandle, memo
} from 'react';

import dynamic from "next/dynamic";
import {observer} from 'mobx-react';

import BlockWrapper from "./_BlockWrapper";
import useFinalizeBlock from "../../../hooks/useFinalizeBlock";
import {ErrorBoundary} from "../../utilities/ErrorBoundary";
import {StoreContext} from "../../../stores/StoreLoader";
import {IView} from "../../../stores/InterfaceStore";

const blockTypes = {
    'sharedfiles': dynamic(() => import('./SharedFilesBlock/SharedFilesBlock')),
    'organization': dynamic(() => import('./OrganizationBlock')),
    'school': dynamic(() => import('./OrganizationBlock')),
    'district': dynamic(() => import('./OrganizationBlock')),
    'classroom': dynamic(() => import('./OrganizationBlock')),
    'person': dynamic(() => import("./PersonBlock")),
    'department': dynamic(() => import("./GroupBlock/GroupBlock")),
    'section': dynamic(() => import("./GroupBlock/GroupBlock")),
    'group': dynamic(() => import("./GroupBlock/GroupBlock")),
    'team': dynamic(() => import("./GroupBlock/GroupBlock")),
    'eventlist': dynamic(() => import("./EventsBlock/EventListBlock")),
    'quicklink': dynamic(() => import("./QuickLinksBlock/QuicklinkBlock")),
    'html': dynamic(() => import("./HtmlBlock/HtmlBlock")),
    "content_item": dynamic(() => import("./ContentItemBlock/ContentItemBlock")),
    'news': dynamic(() => import("./NewsBlock/NewsBlock")),
    'message': dynamic(() => import("./MessageBlock/MessageBlock")),
    'video': dynamic(() => import("./VideoBlock/VideoBlock")),
    'facebook': dynamic(() => import("./SocialBlocks/FacebookBlock")),
    'pinterest': dynamic(() => import("./SocialBlocks/PinterestBlock")),
    'instagram': dynamic(() => import("./SocialBlocks/InstagramBlock")),
    'twitter': dynamic(() => import("./SocialBlocks/TwitterBlock")),
    'poll': dynamic(() => import("./PollBlock/PollBlock")),
    'page': dynamic(() => import("./PageBlock/PageBlock")),
    'staff': dynamic(() => import("./StaffBlock/StaffBlock")),
    'weather': dynamic(() => import("./WeatherBlock/WeatherBlock"), {ssr: false}),
    'youtube': dynamic(() => import("./VideoBlock/VideoBlock")),
    "loading": dynamic(() => import("./LoadingBlock")),
    "error": dynamic(() => import("./ErrorBlock")),
    "search": dynamic(() => import("./SearchResultBlock/SearchResultBlock")),
    "shop": dynamic(() => import("./ShopBlock/ShopBlock")),
};

export type BlockProps = {
    blockObj: AnyBlockObj,
    sizeX: number,
    sizeY: number,
    packeryLayout?: () => void,
    loading?: boolean,
    tickLoadedAsync?: () => void,
    gridType: string,
    isFirst: boolean,
    locksAreEnabled: boolean,
    maxColumns: number,
    normalizedFirstCharacter: string,
    isBlockPreview: boolean,
    hasMicrosite: boolean,
} & ({
    outsideGrid: false,
    rowHeight: number,
    w: number,
    h: number,
    static: boolean,
    x?: number,
    y?: number,
    isResizable: boolean,
    isDraggable: boolean,
    maxW?: number,
    maxH?: number,
} | {
    outsideGrid: true,
    rowHeight?: never,
    w?: never,
    h?: never,
    static?: never,
    x?: never,
    y?: never,
    isResizable?: never,
    isDraggable?: never,
})

// individual blocks should extend this type with blockObj
export interface IBlockComponentProps {
    sizeX: number,
    sizeY: number,
    htmlId: string,
    blockRef: RefObject<HTMLDivElement>,
    setShowExpandButton: SetStateAction<any>,
    outsideGrid: boolean,
    isBlockPreview: boolean,
    blockHovered: boolean,
}

const Block = memo(observer(forwardRef((props: PropsWithChildren<BlockProps>, outerRef: RefObject<any>) => {

    // this is here so that we react to size changes, it's a must so that non-grid blocks work
    const {interfaceStore} = useContext(StoreContext);
    const [showExpandButton, setShowExpandButton] = useState(false);
    const [blockExpanded, setBlockExpanded] = useState(false);
    const blockRef = useRef(null);
    const {blockObj, hasMicrosite, isBlockPreview, outsideGrid, tickLoadedAsync} = props;

    useImperativeHandle(outerRef, () => blockRef.current, []);

    useFinalizeBlock(blockObj, isBlockPreview);
    const htmlId = `item-${outsideGrid ? 'edit-' : ''}${blockObj.id}`;
    const isSearchResult = interfaceStore.view === IView.SEARCH;

    let blockType: string = blockObj.blockType;
    if ((!blockObj.asyncLoaded && blockObj.loadAsync) || props.loading && !isSearchResult) {
        blockType = "loading";
    } else if (!isSearchResult && blockObj.errorMessage && blockObj.errorMessage !== "") {
        blockType = "error";
    }
    const BlockComponent = useCallback(props => {
        const Component = blockTypes[blockType] || "error";
        return <Component {...props} />;
    }, [blockType])

    useEffect(() => {
        if (blockObj.asyncLoaded) {
            if (tickLoadedAsync) tickLoadedAsync()
            blockType = blockObj.blockType;
        }
    }, [blockObj.asyncLoaded])

    return (
        <ErrorBoundary>
            <BlockWrapper
                {...props}
                sizeX={props.sizeX}
                sizeY={props.sizeY}
                gridPosX={props.x}
                gridPosY={props.y}
                locksAreEnabled={props.locksAreEnabled}
                maxColumns={props.maxColumns}
                normalizedFirstCharacter={props.normalizedFirstCharacter}
                blockRef={blockRef}
                ref={outerRef}
                blockObj={blockObj}
                htmlId={htmlId}
                blockExpanded={blockExpanded}
                setBlockExpanded={setBlockExpanded}
                showExpandButton={showExpandButton}
                hasMicrosite={hasMicrosite}
                isSearchResult={isSearchResult}
                gridLocked={props.static}
                rowHeight={props.rowHeight}
            >
                <BlockComponent
                    sizeX={props.blockObj.sizeX}
                    sizeY={props.blockObj.sizeY}
                    blockObj={blockObj}
                    htmlId={htmlId}
                    blockRef={blockRef}
                    setShowExpandButton={setShowExpandButton}
                    outsideGrid={outsideGrid}
                    isBlockPreview={isBlockPreview}
                />
                {props.children}
            </BlockWrapper>
        </ErrorBoundary>
    );
})));

export default Block;
