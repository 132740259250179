import {Children, cloneElement, ReactElement, forwardRef, ReactNode, RefObject, useContext, useRef, useState} from 'react';
import classNames from "classnames";
import {StoreContext} from "../../../stores/StoreLoader";
import {observer} from "mobx-react";
import {buildCleverFeedUrl, gridTypesEnum} from "../../../utils/SchoolBlocksUtilities";

import {IView} from "../../../stores/InterfaceStore";
import dynamic from "next/dynamic";
import {isSchoolFeedStandalone} from "../../../../../packages/components/utils/SchoolBlocksUtilities";
import SafeRouter from "../../utilities/SafeRouter";

const BlockHoverButtons = dynamic(() => import("./BlockHoverButtons"));
const SearchResultFooter = dynamic(() => import("./_SearchResultFooter"));
const ExpandButton = dynamic(() => import("./ExpandButton"));

interface BlockWrapperProps {
    blockExpanded: boolean,
    blockObj: AnyBlockObj,
    blockRef: RefObject<any>,
    children?: ReactNode,
    className?: string,
    gridType: string,
    htmlId: string,
    isFirst: boolean,
    locksAreEnabled: boolean,
    maxColumns: number,
    normalizedFirstCharacter: string,
    outsideGrid: boolean,
    packeryLayout?: () => void,
    sizeX: number,
    sizeY: number,
    showExpandButton: boolean,
    setBlockExpanded: (value: boolean) => void,
    hasMicrosite: boolean,
    isSearchResult: boolean,
    style?: {[key: string]: string},
    gridLocked?: boolean,
    gridPosX?: number,
    gridPosY?: number,
    rowHeight?: number,
}

const BlockWrapper = observer(forwardRef((props: BlockWrapperProps, ref: RefObject<any>) => {
    const {
        locksAreEnabled,
        blockObj,
        blockRef,
        gridType,
        outsideGrid,
        isFirst,
        normalizedFirstCharacter,
        ...restProps
    } = props;
    const {gridStore, modalStore, userStore, interfaceStore} = useContext(StoreContext);
    const [innerHeight, setInnerHeight] = useState(0);
    const [blockHovered, setBlockHovered] = useState(false);

    const blockStyle = {
        "overflow": gridType === gridTypesEnum.ALPHA_GRID ? "visible" : "hidden",
    };

    if (props.style) {
        // when part of a grid, these are set by react-grid-layout
        blockStyle["position"] = props.style.position;
        blockStyle["left"] = props.style.left;
        blockStyle["top"] = props.style.top;

        // setting width/height screws up the A, B, C headers in alpha grids, but these values are only necessary
        // for resizing which isn't allowed in alpha grids anyway
        if (gridType !== gridTypesEnum.ALPHA_GRID) {
            blockStyle["width"] = props.style.width;
            blockStyle["height"] = props.style.height;
        }
    }

    const isModalTrigger = ["school", "district", "organization"].includes(blockObj.blockType);

    const widthCssClass = 'sb-w' + props.sizeX;

    const wrapperClassNames = classNames(
        'sb-block',
        'sb-type-' + blockObj.blockType,
        widthCssClass,
        'sb-min-height',
        props.className,
        {
            [`sb-h${props.sizeY}`]: !props.isSearchResult,
            [`sb-search-h${props.sizeY}`]: props.isSearchResult,
            'sb-position-locked': props.gridLocked || blockObj.fluxLocked, // added separate flux key so that we can influence this class programmatically without altering the positionIsLocked data attribute
            'hidden': !!(gridStore && !gridStore.blockVisibility.get(blockObj.id)?.isVisible && !outsideGrid),
            'sb-flux-capacitor-resized': blockObj.fluxResized,
            'sb-flux-capacitor-moved': blockObj.fluxMoved,
            'sb-flux-capacitor-deleted': blockObj.fluxDeleted,
            'sb-flux-capacitor-parent-deleted': blockObj.fluxParentDeleted,
            'sb-flux-capacitor-locked': blockObj.fluxLocked,
            'sb-flux-capacitor-unlocked': blockObj.fluxUnlocked,
        },
    );

    const isResizing = props.className && !!/resizing/.exec(props.className); // react-grid-layout passes down this class
    const isDragging = props.className && !!/dragging/.exec(props.className);

    const wrapperHtmlAttributes = {
        'id': props.htmlId,
        'role': isModalTrigger ? 'button' : 'article',
        'aria-label': isModalTrigger ? 'Click to open ' + blockObj.filterString + ' modal'
            : blockObj.filterString,
        'data-id': blockObj.id,
        'data-blocktype': blockObj.blockType,
        'data-load-async': blockObj.loadAsync,
        'data-size-x': blockObj.sizeX,
        'data-size-y': blockObj.sizeY,
        'data-pos-x': blockObj.posX,
        'data-pos-y': blockObj.posY,
        'data-filter-string': blockObj.filterString,
        'data-filter-category': blockObj.filterCategories,
        'data-name': blockObj.name,
    };

    const blockContainerClassNames = classNames({
        'sb-block-container': true,
        'sb-withSearchFooter': props.isSearchResult,
    });

    let header;
    if (props.isSearchResult) {
        const footerData = blockObj.footerData;
        header = <SearchResultFooter blockObj={blockObj}
                                     url={footerData.url}
                                     parentText={footerData.parentText}
                                     parentType={footerData.parentType}
                                     grandParentText={footerData.grandParentText}
                                     cssWidthClass={widthCssClass}
                                     cssColorClass={footerData.cssColorClass}/>
    }

    function handleClick() {
        if (blockObj.blockType ) {
            if (isSchoolFeedStandalone()) {
                if (blockObj.blockType === "school") {
                    SafeRouter.push(`/pages/${blockObj.id}`, `/pages/${blockObj.id}`, {shallow: false, locale: false});
                }
                else {
                    const url = buildCleverFeedUrl(blockObj.id);
                    SafeRouter.push(url, url, {shallow: false, locale: false});
                }
            }
            else {
                modalStore.addModal({
                    type: blockObj.blockType === "organization" ? "sbOrganization" : "schoolDistrict",
                    orgId: blockObj.id,
                    id: blockObj.id,
                    blockObj,
                });
            }
        }
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleClick();
        }
    }

    const hasIframe = useRef(false);

    const canShowHoverButtons = gridType === gridTypesEnum.FLEX_GRID &&
        userStore.editor &&
        gridStore.canEdit &&
        !props.outsideGrid;

    function handleMouseEnter() {
        setBlockHovered(true);

        if (
            canShowHoverButtons &&
            !blockHovered &&
            blockObj.blockType !== "content_item"
        ) {
            setBlockHovered(true);
            hasIframe.current = !!props.blockRef?.current.querySelector("iframe");
        }

    }

    function handleMouseLeave() {
        setBlockHovered(false);

        if (
            canShowHoverButtons &&
            !isResizing
        ) {
            setBlockHovered(false);
        }
    }

    return <article
            ref={props.blockRef}
            onClick={isModalTrigger ? handleClick : () => {
            }}
            onKeyDown={isModalTrigger ? handleKeyDown : () => {
            }}
            className={wrapperClassNames}
            {...wrapperHtmlAttributes}
            tabIndex={isModalTrigger ? 0 : undefined}
            style={blockStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
        {gridType === gridTypesEnum.ALPHA_GRID && <div className={"sb-multigrid-block-header"}>
            {isFirst && <span aria-label={`Blocks beginning with ${normalizedFirstCharacter === "#" ? "a number" : `the letter ${normalizedFirstCharacter}`}`}>
                {normalizedFirstCharacter}
            </span>}
        </div>}
        {canShowHoverButtons && <BlockHoverButtons
            {...restProps}
            blockRef={blockRef}
            blockObj={blockObj}
            // @ts-ignore not sure how to handle this...
            hidden={!blockHovered || blockObj.blockModel.deleted}
            hasMicrosite={props.hasMicrosite}
            // when inside grid, these values are passed down from GridItem so are guaranteed
            gridPosX={props.gridPosX as number}
            gridPosY={props.gridPosY as number}
        />}
        {header}
        <div className={blockContainerClassNames}>
            {(isResizing || isDragging) && hasIframe.current && <div className="ui-resizable-iframeFix" style={{
                width: '100%',
                height: '100%',
                position: "absolute",
                opacity: "0.001",
                zIndex: 10000,
            }}/>}
            {Children.map(props.children, child => {
                if (child) {
                    return cloneElement((child as ReactElement), {
                        blockHovered: blockHovered,
                        setInnerHeight,
                    })
                }
                return child;
            })}
            {props.showExpandButton && props.rowHeight && <ExpandButton
                blockObj={props.blockObj}
                rowHeight={props.rowHeight}
                expanded={blockObj.expanded}
                innerHeight={innerHeight}
            />}
        </div>
    </article>
}));

export default BlockWrapper;
