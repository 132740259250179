import {useContext} from 'react';
import {runInAction} from "mobx";

import ajaxGetQueue, {watsonGetQueue} from "../backends/AjaxQueue";
import { DefaultController } from "../backends/YiiRoutes";
import {isJson} from "../components/admin/spinup/utilities";
import {StoreContext} from "../stores/StoreLoader";
import WatsonApi from "../backends/WatsonApi";
import useAsyncEffect from "./useAsyncEffect";

export default function useFinalizeBlock(blockObj, isBlockPreview) {
    const {i18nStore, organizationStore} = useContext(StoreContext);

    useAsyncEffect(async () => {
        if (!isBlockPreview && !blockObj.asyncLoaded) {
            function legacyFinalizeBlock() {
                const blockID = blockObj.id;
                const task = {
                    orgID: organizationStore.currentOrganization.id,
                    url: DefaultController.blockFetch(i18nStore.currentOrgSupportedLocale, blockID),
                    data: {
                        'sizeX': blockObj.sizeX,
                        'sizeY': blockObj.sizeY,
                        'useAbsoluteUrls': true,
                        'usingReact': true,
                        'swcache': true, // caught be service worker
                    },
                    dataType: 'json',
                };

                ajaxGetQueue.push(task, 10, (err, data) => {
                    if (data?.html && isJson(data.html)) {
                        runInAction(() => {
                            const newSettings = JSON.parse(data.html);
                            if (blockObj.setAttributes) {
                                blockObj.setAttributes({
                                    ...newSettings,
                                    asyncLoaded: true,
                                });
                            }
                        })
                    }
                });
            }

            if (["facebook", "instagram"].includes(blockObj.blockType)) {
                // these blocks use async via Watson
                const client = await WatsonApi();
                const task = {
                    url: client.apis.organizations.organizations_blocks_async_block,
                    params: {
                        organization_pk: organizationStore.currentOrganization.id,
                        id: blockObj.id,
                    }
                }
                watsonGetQueue.push(task, 10, result => {
                    const resp = JSON.parse(result.data);
                    runInAction(() => {
                        blockObj.setAttributes({
                            htmlOutput: resp.htmlOutput,
                            asyncLoaded: true,
                        });
                    })
                });
            } else if (blockObj.loadAsync) {
                // should only run for async blocks that haven't been converted over to Watson yet
                legacyFinalizeBlock();
            }
        }
    }, [blockObj.asyncLoaded]);
    // this is here so in ToolDrawerGrid -> ExampleBlock we update the block from loading to whatever when we get a new blockObj
    if (!blockObj.loadAsync && isJson(blockObj.htmlOutput)) {
        runInAction(() => {
            let newSettings = JSON.parse(blockObj.htmlOutput);
            const newBlockObj = newSettings?.blockObj;
            // for some reason the youtube/video blocks send blockObj as htmlOutput instead of filling the htmlOutput.
            if (newBlockObj) newSettings = {...newSettings, ...newBlockObj};
            if (blockObj.setAttributes) {
                blockObj.setAttributes({...newSettings});
            }
        })
    }
}
